import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { RichText } from 'prismic-reactjs';
import {
  Container, Button, Title,
} from './styled';
import Picker from './Picker';
import Steps from './Steps';

function Section2({
  action_button_seller, action_button_buyer, seller_steps, buyer_steps, section_title,
}) {
  const [stepsType, setStepsType] = useState(0);
  return (
    <Container>
      <Title><RichText render={section_title} /></Title>
      <Picker setStepsType={setStepsType} stepsType={stepsType} />
      <Steps
        seller_steps={seller_steps}
        buyer_steps={buyer_steps}
        stepsType={stepsType}/>
      <Button onClick={() => navigate('/login')}>{stepsType === 0 ? action_button_seller : action_button_buyer}</Button>
    </Container>
  );
}

export default Section2;
