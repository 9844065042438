import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 37vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 3.375vw;
  color: #464855;
  letter-spacing: 0;
`;

export const Button = styled.div`
  background: #6B969D;
  border-radius: 0.6250vw;
  font-family: sofia-pro, sans-serif; 
  font-weight: 600;
  font-size: 1.750vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.563vw;
  width: 18.56vw;
  height: 3.500vw;
  text-transform: uppercase;
  cursor: pointer;
`;