import React from 'react';
import { Container } from './styled';
import Step from './Step';
const buyer_steps = [
  {
    subheading: 'bridal block?',
    title: 'Step 1',
    content: 'You’ve spent hours on Pinterest and Instagram and believe you found the perfect dress... but it’s out of your price point and may not come in time for your wedding. ',
  },
  {
    subheading: 'you found #sobridely',
    title: 'Step 2',
    content: 'Your local boutique tells you about So Bridely, where you discover your dream dress!',
  },
  {
    subheading: 'connect with sellers',
    title: 'Step 3',
    content: 'You create an account, contact the seller and the two of you agree on the perfect price!',
  },
  {
    subheading: 'lets celebrate',
    title: 'Step 4',
    content: 'After placing your order and making a secure payment through the So Bridely  website, your dream wedding dress arrives within just a few short days!',
  },
  {
    subheading: 'you did it',
    title: 'Step 5',
    content: 'It’s everything you could have ever imagined, and you officially feel SO BEAUTIFUL, SO CONFIDENT, SO BRIDELY!',
  },
];

const seller_steps = [
  {
    subheading: 'you found #sobridely',
    title: 'Step 1',
    content: 'You spent hundreds if not thousands of dollars on your dream wedding dress, and although it was well worth it, you’ve decided it would be nice to make some of that money back. ',
  },
  {
    subheading: 'upload your dress',
    title: 'Step 2',
    content: 'You create a So Bridely profile and share pictures of your gown with interested brides from across the country.',
  },
  {
    subheading: 'connect with brides',
    title: 'Step 3',
    content: 'After some negotiation through So Bridely’s secure chat, you and the buyer agree on the perfect price! You are now ready to ship your dress!',
  },
  {
    subheading: 'secure & ship',
    title: 'Step 4',
    content: 'Print the shipping label, follow the instructions on how to safely package your gown, and share the magic of finding the perfect dress with another bride! Now it’s time to get paid!',
  },
  {
    subheading: 'make your money honey',
    title: 'Step 5',
    content: 'Not only have you made some extra cash, but you’ve also been a part of another bride’s journey to feeling SO BEAUTIFUL, SO CONFIDENT, SO BRIDELY!',
  },
];

function Steps({ stepsType }) {
  let currentSteps = stepsType === 0 ? buyer_steps : seller_steps;
  return (
    <Container>
      {
        currentSteps.map((stepInfo, index) => (
          <Step index={index} key={index} {...stepInfo} />
        ))
      }
    </Container>
  );
}

export default Steps;
