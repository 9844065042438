import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 6.763vw;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
  line-height: 7.246vw;
`;

export const Button = styled.div`
  background: #6B969D;
  border-radius: 2.415vw;
  font-family: sofia-pro, sans-serif; 
  font-weight: 600;
  font-size: 6.763vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 21.26vw;
  width: 71.74vw;
  height: 13.53vw;
  text-transform: uppercase;
  cursor: pointer;
`;