import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 3.313vw;
`;

export const Image = styled.img`
  width: 16.56vw;
  height: 16.56vw;
  margin-right: 1.688vw;
  background-color: lightgrey;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 3.375vw;
  color: #464855;
  letter-spacing: 0;
  margin-top: 3.250vw;
`;

export const Content = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 1.125vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 26px;
  width: 28.19vw;
`;