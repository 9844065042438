import React, { useState } from 'react';
import { navigate } from '@reach/router';
import {
  Container, Button, Title,
} from './styled';
import Picker from './Picker';
import Steps from './Steps';

function Section2() {
  const [stepsType, setstepsType] = useState(0);
  return (
    <Container>
      <Title>Choose your experience:</Title>
      <Picker setstepsType={setstepsType} stepsType={stepsType} />
      <Steps stepsType={stepsType}/>
      <Button onClick={() => navigate('/login')}>{stepsType === 0 ? 'Find My Dress!' : 'Start Selling!'}</Button>
    </Container>
  );
}

export default Section2;
