import React from 'react';
import Column from '../../../../../Column';
import {
  Container, Content, Title, Index, Text,
} from './styled';

function Step({
  index, title, content, subheading,
}) {
  return (
    <Container>
      <Column style={{
        backgroundColor: '#7DBEC8', height: 300, width: 300,
        overflow:'hidden', position: 'relative', alignSelf: 'center',
      }}>
        <Index>#{index + 1}</Index>
        <Text>{subheading}</Text>
      </Column>
      <Title>{title}:</Title>
      <Content>{content}</Content>
    </Container>
  );
}

export default Step;
