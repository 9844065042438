import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 60px;
  width:  100%;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 54px;
  color: #464855;
  letter-spacing: 0;
  margin-top: 30px
`;

export const Content = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 20px;
  width: 100%;
`;

export const Index = styled.div`
  opacity: 0.4;
  font-family: SilverSouthScript;
  font-size: 327px;
  color: #F3F0EB;
  letter-spacing: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 170px;
`;

export const Text = styled.div`
  font-family: SilverSouthSerif;
  font-size: 47px;
  color: #124C56;
  letter-spacing: 0;
  text-align: right;
  line-height: 62px;
  position: absolute;
  bottom: 20px;
  right: 40px;
  width: calc(100% - 80px);
`;