import React from 'react';
import { Container, BackgroundImage } from './styled';
import background_image from './background.jpg';
// import Section1 from './Section1';
import Section2 from './Section2';

function HowItWorks() {
  return (
    <Container>
      <BackgroundImage src={background_image}/>
      {/* <Section1 /> */}
      <Section2 />
    </Container>
  );
}

export default HowItWorks;
