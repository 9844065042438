import React from 'react';
import { Container, Option } from './styled';

function Picker({ stepsType, setStepsType }) {
  return (
    <Container>
      <Option onClick={() => setStepsType(0)} active={stepsType === 0}>I am a buyer</Option>
      <Option onClick={() => setStepsType(1)} active={stepsType === 1} style={{ marginLeft: '4.188vw' }}>I am a seller</Option>
    </Container>
  );
}

export default Picker;
