import React from 'react';
import { Container, Option } from './styled';

function Picker({ stepsType, setstepsType }) {
  return (
    <Container>
      <Option onClick={() => setstepsType(0)} active={stepsType === 0}>I am a buyer</Option>
      <Option onClick={() => setstepsType(1)} active={stepsType === 1}>I am a seller</Option>
    </Container>
  );
}

export default Picker;
