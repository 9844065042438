import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import HowItWorksDesktop from '../components/Desktop/HowItWorks';
import HowItWorksMobile from '../components/Mobile/HowItWorks';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function HowItWorks({ setMetaTags, data }){
  const pageProps = data?.prismic?.how_it_works;

  useEffect(() => {
    const description = 'Check out how SoBridely works';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      pageData={pageProps}
      mobile={<HowItWorksMobile />}
      desktop={<HowItWorksDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(HowItWorks);


export const query = graphql`
query {
  prismic {
    how_it_works(lang: "en-us", uid: "how-it-works") {
        action_button_buyer
        action_button_seller
        background_image
        seller_steps {
          label
          image 
          content
        }
        buyer_steps {
          label
          image
          content
        }
        section_title
    }
  }
}
`;