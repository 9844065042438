import React, { useContext } from 'react';
import { AppContext } from '../../../components/AppProvider';
import { Container, BackgroundImage } from './styled';
import Section2 from './Section2';


function HowItWorks() {
  const { data } = useContext(AppContext);
  const { background_image: { url } } = data;
  return (
    <Container>
      <BackgroundImage src={url}/>
      <Section2 {...data} />
    </Container>
  );
}

export default HowItWorks;
