import React from 'react';
import { Container } from './styled';
import Step from './Step';

function Steps({
  stepsType, seller_steps, buyer_steps,
}) {
  let currentSteps = stepsType === 0 ? buyer_steps : seller_steps;
  return (
    <Container>
      {
        currentSteps.map((stepInfo, index) => (
          <Step key={index} {...stepInfo} />
        ))
      }
    </Container>
  );
}

export default Steps;
