import React from 'react';
import { RichText } from 'prismic-reactjs';
import {
  Container, Image, Content, Title,
} from './styled';


function Step(props) {
  const {
    image:{ url }, label, content,
  } = props;
  return (
    <Container>
      <Image src={url} />
      <div>
        <Title>{label}</Title>
        <Content><RichText render={content} /></Content>
      </div>
    </Container>
  );
}

export default Step;
