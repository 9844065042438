import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F3F0EB;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  object-fit: contain;
  position: absolute;
`;