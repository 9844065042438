import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 3.938vw;
`;

export const Option = styled.div`
  margin-left: 4.188vw;
  color: ${props => props.active ? 'white' : '#6B969D'};
  background: ${props => props.active ? '#6B969D' : 'transparent'};
  font-family: sofia-pro, sans-serif; 
  font-weight: 600;
  font-size: 1.750vw;
  letter-spacing: 1px;
  text-align: center;
  width: 15.56vw;
  height: 3.500vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;